<template>
  <div class="results_parent" v-if="dashboard">
    <h1>Edit Dashboard :: {{ dashboard.name }}</h1>

    <b-button :href="'/dashboard/' + dashboard.name" variant="success"
      >View Dashboard</b-button
    >
    <b-form-group>
      <editor
        id="schema_editor"
        v-if="schema_string"
        v-model="schema_string"
        @init="editorInit"
        lang="json"
        theme="crimson_editor"
        height="600pt"
      ></editor>
    </b-form-group>
    <b-card>
      <b-form inline>
        <b-button
          @click="update_dashboard()"
          variant="outline-success"
          size="lg"
          >Save</b-button
        >
        <b-button
          @click="format_json()"
          variant="outline-primary"
          size="lg"
          class="pull-right"
          >Format</b-button
        >
        <b-button
          @click="reset_schema_from_dashboard()"
          variant="outline-danger"
          size="lg"
          class="pull-right"
          >Reset</b-button
        >
        <b-form-group>
          <b-form-select
            v-if="dashboard_list"
            v-model="selected_dashboard_to_copy"
          >
            <b-form-select-option
              v-for="dashboard in dashboard_list"
              :key="dashboard.id"
              :value="dashboard.id"
              >{{ dashboard.name }} -
              {{ dashboard.boundary_name }}</b-form-select-option
            >
          </b-form-select>
          <b-button
            @click="copy_from_dashboard()"
            variant="outline-success"
            size="lg"
            class="pull-right"
            >Copy from Dashboard</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import DashboardService from '../services/dashboard.service';
import vue2_ace_editor from "vue2-ace-editor";

import brace from "brace/ext/language_tools"; //language extension prerequsite...

import brace0 from "brace/mode/json";

import brace01 from "brace/mode/less";

import brace012 from "brace/theme/crimson_editor";

export default {
  name: 'EditDashboard',
  components: {
    editor: vue2_ace_editor,
  },
  data() {
    return {
      dashboard: null,
      schema_string: '',

      // The dashboard the user wants to copy from
      selected_dashboard_to_copy: null,
    };
  },
  methods: {
    editorInit: function () {
    },
    update_dashboard() {
      this.dashboard.schema = JSON.parse(this.schema_string);
      console.log(this.dashboard);
      DashboardService.updateDashboard(this.dashboard.id, this.dashboard).then(
        (response) => {
          alert('Save complete');
        },
        (error) => {
          alert('Could not save schema');
        }
      );
    },
    copy_from_dashboard() {
      if (!this.selected_dashboard_to_copy) {
        console.error('No dashboard selected to copy');
        return;
      }
      console.log('Copying from dashboard:', this.selected_dashboard_to_copy);
      DashboardService.getDashboardSchema(
        this.$route.params.dashboard_id
      ).then(
        (response) => {
          this.dashboard.schema = response.data['dashboard'].schema;
          this.set_schema_string();
          alert(
            'Copied from dashboard to existing one. Click save to overwrite dashboard.'
          );
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    format_json() {
      // Parse the JSON as is, and then set it again to format
      return this.set_schema_string(JSON.parse(this.schema_string));
    },
    reset_schema_from_dashboard() {
      // Set the schema as the original schema from the return dashboard
      return this.set_schema_string(this.dashboard.schema);
    },
    set_schema_string() {
      this.schema_string = JSON.stringify(this.dashboard.schema, null, 4);
    },
  },
  mounted() {
    DashboardService.getDashboardSchema(this.$route.params.dashboard_id).then(
      (response) => {
        this.dashboard = response.data['dashboard'];
        this.reset_schema_from_dashboard();
        console.log('Dashboard:');
        console.log({ ...this.dashboard });
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
    DashboardService.getAllDashboards().then(
      (response) => {
        this.dashboard_list = response.data['dashboards'];
        console.log('Other Dashboards:');
        console.log(this.dashboard_list);
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style scoped>
.btn {
  margin: 8pt;
}
.results_parent {
  width: 80%;
  min-width: 400pt;
  margin: auto;
  padding: 16pt;
  background-color: #e9ecef;
}

#schema_editor {
  font-size: 16pt;
  width: 90%;
  min-width: 500pt;
  height: 600pt;
}

.pull_right {
  float: right;
}
</style>
